<template>
    <div>
        <div class="main">
            <div class="title">
                {{major.cat_name}}中心
            </div>
            <div class="header">
                <img :src="major.image_url" alt="" >
                <div class="user text_left" >
                    <div class="user_detail">
                        {{major.cat_name}}：{{major.name}}
                    </div>
                    <div class="phone">
                        {{major.brief}}
                    </div>
                </div>
                <el-button type="primary" class="jump" plain @click="right_chat(major)">马上咨询</el-button>
            </div>
            <div class="list_box" v-html="major.intro"></div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            major_id:'',
            major:''
        }
    },
    created(){
        if(this.$route.query.id){
            this.major_id = this.$route.query.id
        }
        console.log(this.major_id)
        this.loadDetail()
     
    },
    mounted(){
        
    },
    methods:{
        //加载律师/专家的个人信息
        loadDetail(){
            this.$api.post('goods.getdetial',{id:this.major_id},(res)=>{
					if(res.data.status){
                        this.major = res.data.data
					}else{
                     this.$message.error(res.data.msg)
					}
			})
        },
        // 立即咨询
        right_chat(item){
            this.$api.userpost('cart.add',{product_id:item.product.id},(res)=>{
                if(res.data.status){
                    let data = {
                        cart_ids:res.data.data
                    }
                    this.$api.userpost('order.create',data,(res)=>{
								if(res.status){
									if(res.data.data.order_amount == 0){
                                        this.$message({message:'下单成功',type:'success'})
                                        this.vip_order = res.data.data.order_id
                                        this.show_pay = true
                                        this.$router.push({path:'/chat'})
									}else{
										let data = {
											name:'资讯订单',
											money:res.data.data.order_amount,
											order_id:res.data.data.order_id,
                                            type:1,//写死的
                                            chat:1
										}
										
										this.$router.push({path:'/pay',query:{order_detail:JSON.stringify(data)}})
                                    }
								}else{}
							})
                }else{ }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .main{
        padding: 30px;
        margin: 40px auto;
        width: 1140px;
        min-height: 816px;
        background: #F1F4F8;
        opacity: 1;
        border-radius: 30px;
    }
    .title{
       text-align: left;
       font-size: 26px; 
       color: #144591;
    }
    .please_login{
        font-size: 25px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .header{
        position: relative;
        width: 1140px;
        height: 160px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 10px;
        margin-top: 15px;
        display: flex;
        align-items: center;
        img{
            margin-left: 30px;
            height: 106px;
            width: 106px;
        }
        .user{
            margin-left: 20px;
            display: flex;
            flex-direction:column;
            justify-content: space-between;
            height: 106px;
            .user_detail{
                font-weight: bold;
                font-size: 26px;
                color: #000;
            }
            .phone{
                font-size: 20px;
                overflow: hidden;
                width: 800px;
            }
        }
        .right_icon{
            position: absolute;
            height: 26px;
            width: 14px;
            right: 25px;
        }
    }
    
    .jump{
        height: 50px;
        font-size: 23px;
    }

    .two{
        display: flex;
        justify-content: space-between;
        height: 100px;
        margin-top: 30px;
        .one{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 555px;
            height: 100px;
            background: #FFFFFF;
            opacity: 1;
            border-radius: 10px;
            text-align: center;
            font-size: 26px;
            color: #666;
            img{
                height: 34px;
                width: 40px;
                margin-right: 20px;
            }
        }
    }

    .list_box{
        margin-top: 30px;
        width: 1100px;
        padding: 20px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 10px;
    }
    .mask_box{
        position: relative;
        width: 460px;
        height: 245px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 10px;
        .close_img{
            height: 30px;
            width: 30px;
            position: absolute;
            right: 22px;
            top: 22px;
        }
        .warning_img{
            height: 38px;
            width: 43px;
            margin-top: 45px;
        }
        .button_box{
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            margin-top: 40px;
            width: 220px;
            .confirm{
                width: 95px;
                height: 32px;
                background: linear-gradient(162deg, #3C7DE2 0%, #2163C7 100%);
                opacity: 1;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                font-size: 16px;
                color: #FFF;
            }
            .cancel{
                width: 95px;
                height: 32px;
                border: 1px solid #BBBBBB;
                opacity: 1;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                font-size: 16px;
                color: #BBB;
            }
        }
    }
</style>